<script>
    export default {
        name: 'PlayerButton',
        props: {
            player: {
                type: Object,
                required: true
            },
            cssClass: {
                type: String,
                required: false,
                default: ''
            }
        },
        methods: {
            playerClick() {
                this.$emit('select', this.player.id)
            }
        }
    }
</script>

<template>
    <div class="player-button"
         :class="cssClass">
        <button @click.stop.prevent="playerClick"
                class="btn btn-outline-secondary">
            #{{ player.number }}
        </button>
    </div>
</template>
<style lang="scss">
    .player-button button {
        padding: .75rem;
        width:4rem;
    }
</style>