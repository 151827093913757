<template>
    <div>
        <button class="btn btn-outline-secondary me-2 px-4"
                @click.stop.prevent="$emit('decrease', field)">
            <font-awesome-icon icon="caret-left" />
        </button>
        <button class="btn btn-outline-secondary px-4"
                @click.stop.prevent="$emit('increase', field)">
            <font-awesome-icon icon="caret-right" />
        </button>
    </div>
</template>
<script>
    export default {
        name: 'FieldPositionUpDown',
        props: {
            field: {
                type: String,
                required: true
            }
        }
    }
</script>