import * as types from './mutation_types';
import axios from 'axios';

////export const fetchUsers = ({ state, dispatch }) => {
////    commit(types.REQUEST_USERS);

////    axios.get(state.endpoint)
////        .then(({ data }) => commit(types.RECEIVE_USERS_SUCCESS, data))
////        .catch((error) => {
////            commit(types.RECEIVE_USERS_ERROR, error)
////            createFlash({ message: 'There was an error' })
////        });
////}

export const getHomePlayers = ({ state, commit }) => {
    axios.get(state.homePlayersEndpoint)
        .then(({ data }) => commit(types.RECEIVE_HOME_PLAYERS, data));
}

export const getVisitingPlayers = ({ state, commit }) => {
    axios.get(state.visitingPlayersEndpoint)
        .then(({ data }) => commit(types.RECEIVE_VISITING_PLAYERS, data));
}

export const moveToPreviousPlay = ({ state, commit }) => {
    // add in possession to the work play, before moving it to history (probably unnecessary)
    commit(types.UPDATE_POSSESSION_WORKING_PLAY, state.teamPossession.abbrev);
    commit(types.UPDATE_DOWN + '_PREVIOUSPLAY', state.workingPlay.down);
    commit(types.UPDATE_YTG + '_PREVIOUSPLAY', state.workingPlay.yardsToGo);
    commit(types.UPDATE_YARDLINE + '_PREVIOUSPLAY', state.workingPlay.yardLine);
    commit(types.UPDATE_YARDLINE_SIDE + '_PREVIOUSPLAY', state.workingPlay.yardLineSide);
}

export const clearWorkingPlay = ({ commit }) => {
    commit(types.UPDATE_DOWN + '_WORKINGPLAY', '');
    commit(types.UPDATE_YTG + '_WORKINGPLAY', '');
    commit(types.UPDATE_YARDLINE + '_WORKINGPLAY', '');
    //commit(types.UPDATE_YARDLINE_SIDE + '_WORKINGPLAY', '');
}

export const changePossession = ({ state, commit }, teamAbbrev) => {
    let team = {}; 
    if (state.homeTeam.abbrev == teamAbbrev)
        team = state.homeTeam;
    else if (state.visitingTeam.abbrev == teamAbbrev)
        team = state.visitingTeam;
    else
        throw new Error(`${teamAbbrev} is invalid.`);

    commit(types.CHANGE_POSSESSION, team);
}