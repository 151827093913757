<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Possession',
        data() {
            return {
                
            }
        },
        computed: {
            ...mapState([
                'homeTeamHasPossession',
                'visitingTeamHasPossession',
                'homeTeam',
                'visitingTeam'
            ]),

            ...mapGetters([
                'homeTeamHasPossession',
                'visitingTeamHasPossession',
            ])

        },
        methods: {
            setPossession(possession) {
                switch (possession) {
                    case 'home':
                        this.changePossession(this.homeTeam.abbrev);
                        break;
                    case 'visiting':
                        this.changePossession(this.visitingTeam.abbrev);
                        break;

                }

            },
            ...mapActions([
                'changePossession'
            ])
        }
    }
</script>

<template>
    <div class="row mb-3">
        <div class="col text-center">
            <font-awesome-icon icon="football-ball"
                               v-show="homeTeamHasPossession"/>
            <button @click.stop.prevent="setPossession('home')"
                    class="btn btn-outline-secondary ms-2">{{ homeTeam.name }}</button>
        </div>
        <div class="col text-center">
            
            <button @click.stop.prevent="setPossession('visiting')"
                    class="btn btn-outline-secondary me-2">{{ visitingTeam.name }}</button>
            <font-awesome-icon icon="football-ball"
                               v-show="visitingTeamHasPossession" />
        </div>
    </div>
</template>