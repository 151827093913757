<script>
    export default {
        name: 'PlayerList',
        props: {
            players: {
                type: Array,
                required: true,
                default() {
                    return [];
                }

            }
        },
        data() {
            return {

            }
        },
        created() {

        },
        computed: {

        },
        methods: {

        }
    }
</script>

<template>
    <div class="row">
        <div v-for="player in players" class="col-6"
             :key="player.id">
            #{{ player.number }} {{ player.name }}
        </div>
    </div>
</template>