import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue'
import './registerServiceWorker'
import '@/assets/style.scss';
import { store } from '@/store/';
Vue.use(Vuex);


// wrapper for local storage that has expiration
import { Plugin } from 'vue2-storage';
Vue.use(Plugin);
Vue.use(Plugin, {
    prefix: 'rvy_',
    driver: 'local',
    ttl: 0,  // forever
    replacer: (key, value) => value
});

// Font Awesome Pro
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFootballBall, faForward, faStepForward, faStepBackward, faCheck, faBackward, faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { faBackspace, faUserTimes, faCalculator } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFootballBall, faBackspace, faForward, faBackward, faStepForward, faStepBackward, faUserTimes, faCheck, faCalculator, faCaretLeft, faCaretRight);

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.productionTip = false

new Vue({
    store,
    render: h => h(App),
}).$mount('#app')
