<script>
    import PlayerButton from './PlayerButton.vue';

    export default {
        name: 'PlayerSelector',
        props: {
            players: {
                type: Array,
                required: true
            },
            showFullRoster: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        components: {
            PlayerButton
        },
        data() {
            return {
                recent: []
            }
        },
        created() {

        },
        computed: {
            showRecentPlayers() {
                return this.recent.length > 0;
            },
            recentPlayers() {
                const vm = this;
                return vm.players.filter(p => vm.recent.includes(p.id));
            },
            restOfPlayers() {
                const vm = this;
                return vm.players.filter(p => !vm.recent.includes(p.id));
            }
        },
        methods: {
            onClearPlayer() {
                this.$emit('clear');
            },
            onCancel() {
                this.$emit('cancel');
            },
            onPlayerSelect(id) {
                this.$emit('select', id);
                this.recent.push(id);
            }
        }
    }
</script>

<template>
    <div>
        <div class="player-button-list"
             v-show="showRecentPlayers">
            <player-button v-for="player in recentPlayers"
                           :player="player"
                           :key="player.id"
                           cssClass="recent"
                           @select="onPlayerSelect"></player-button>
        </div>
        <hr v-show="showRecentPlayers && showFullRoster" />
        <div class="player-button-list"
             v-show="showFullRoster">
            <player-button v-for="player in restOfPlayers"
                           :player="player"
                           :key="player.id"
                           @select="onPlayerSelect"></player-button>
        </div>

    </div>

</template>
<style lang="scss">
    .player-button-list {
        display: flex;
        flex-wrap: wrap;
        /*justify-content: space-between;*/
    }

/*    .player-button.recent button {
        font-size: 130%;
    }*/

    .player-button button {
        margin: .2rem .3rem;
    }
</style>