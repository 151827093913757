export default () => ({
    homePlayers: [],
    visitingPlayers: [],
    homeTeam: { name: 'River Valley', abbrev: 'RV' },
    visitingTeam: { name: 'Lancaster', abbrev: 'Lan' },
    teamPossession: {},
    playHistory: [],
    homePlayersEndpoint: 'data/home.json',
    visitingPlayersEndpoint: 'data/visitor.json',
    previousPlay: {
        down: '',
        yardsToGo: '',
        yardLine: '',
        yardLineSide: '',
        possession: '',
    },
    workingPlay: {
        down: '',
        yardsToGo: '',
        yardLine: '',
        yardLineSide: '',
        offensePlayer1: {},
        offensePlayer2: {},
        tacklers: [],
        possession: '',
        type: ''

    }
});