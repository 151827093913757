export const EXAMPLE_TYPE = 'EXAMPLE_TYPE';
export const RECEIVE_HOME_PLAYERS = 'RECEIVE_HOME_PLAYERS'
export const RECEIVE_VISITING_PLAYERS = 'RECEIVE_VISITING_PLAYERS'
export const CHANGE_POSSESSION = 'CHANGE_POSSESSION';
export const UPDATE_DOWN = 'UPDATE_DOWN';
export const UPDATE_YTG = 'UPDATE_YTG';
export const UPDATE_YARDLINE = 'UPDATE_YARDLINE';
export const UPDATE_YARDLINE_SIDE = 'UPDATE_YARDLINE_SIDE';
export const UPDATE_POSSESSION_WORKING_PLAY = 'UPDATE_POSSESSION_WORKING_PLAY';
export const UPDATE_OFFENSE_PLAYER_1 = 'UPDATE_OFFENSE_PLAYER_1';
export const UPDATE_OFFENSE_PLAYER_2 = 'UPDATE_OFFENSE_PLAYER_2';
export const UPDATE_TACKLERS = 'UPDATE_TACKLERS';