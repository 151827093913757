<template>
    <div>
        <div class="mb-3 row">
            <div class="col-sm-8 d-grid gap-2 mx-auto">
                <button class=" btn btn-outline-secondary" @click.stop.prevent="nextPlayClick">Next Play <font-awesome-icon icon="step-forward" /></button>
            </div>

        </div>
        <div class="mb-3 row">
            <div class="col-5">
                <field-position state-key="previousPlay"
                                :is-current-play="false"></field-position>
            </div>

            <div class="col-2">
                <div class="mb-3 row">
                    <div class="col-sm-12 col-form-label">
                        Yard Line
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col-sm-12 col-form-label">
                        Down
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col-sm-12 col-form-label">
                        Yards To Go
                    </div>
                </div>
            </div>
            <div class="col-5">
                <field-position state-key="workingPlay"
                                @blur-yardline="onBlurYardline"
                                @calc-ytg="onCalcYTG"
                                @no-gain="noGain"></field-position>
            </div>
        </div>
        <div class="mb-3">
            Yards Gain/Lost: {{ this.yardsGainedLost }}
        </div>

    </div>
</template>
<script>

    
    import FieldPosition from './FieldPosition.vue';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import * as mutationTypes from '@/store/mutation_types';


    export default {
        name: 'GamePlay',
        components: {
            FieldPosition,

        },
        props: {

        },
        data() {
            return {

            }
        },
        computed: {

            ...mapState({
                homeAbbrev: state => state.homeTeam.abbrev,
                visitingAbbrev: state => state.visitingTeam.abbrev,
                workingPlay: state => state.workingPlay,
                previousPlay: state => state.previousPlay,
                teamPossession: state => state.teamPossession,
                homePlayers: state => state.homePlayers,
                visitingPlayers: state => state.visitingPlayers
            }),
            ...mapGetters([
                'readyForYardCalculations'
            ]),
            yardsGainedLost() {
                let previous = this.previousPlay.yardLine;
                let working = this.workingPlay.yardLine;
                const teamPossessionAbbrev = this.teamPossession.abbrev;
                const workingYardLineSide = this.workingPlay.yardLineSide;
                const previousYardLineSide = this.previousPlay.yardLineSide;

                if (!this.readyForYardCalculations)
                    return '--'

                if (teamPossessionAbbrev == previousYardLineSide)
                    previous = previous * -1;

                if (teamPossessionAbbrev == workingYardLineSide)
                    working = working * -1;

                return this.modulo(previous,100) - this.modulo(working,100);
            },
            playerSelectButtonsDisabled() {
                return typeof this.teamPossession.abbrev == 'undefined'
            },
        },
        methods: {

            nextPlayClick() {
                this.moveToPrevious();
                this.clearWorking();
                //this.clearPlay();
                //this.calcNewDown(); - handled by yard line blur?
                
            },
            // "fixed" version of modulo to give correct for negative numbers
            // https://stackoverflow.com/a/4467559/3927271
            modulo(a, b) {
                return ((a % b) + b) % b;
            },

            onBlurYardline() {
                this.calcNewDown();
            },
            onCalcYTG() {
                this.calcNewDown();
            },
            ...mapActions({
                moveToPrevious: 'moveToPreviousPlay',
                clearWorking: 'clearWorkingPlay',
                changePossession: 'changePossession'
            }),

            calcNewDown() {
                const down = this.previousPlay.down;
                const yardsToGo = this.previousPlay.yardsToGo;
                
                if (this.readyForYardCalculations) {
                    console.log('here1');
                    // did they make a first down?
                    if (this.yardsGainedLost >= yardsToGo) {
                        this.updateDown(1);
                        return;
                    }

                    // didn't make a first.  TODO - special 4th down, if punt/kickoff

                    // was it a 4th down?
                    if (down == '4') {
                        // they didn't make it, turn over possession
                        this.updateDown(1);
                        this.switchPossession();
                        return;
                    }

                    // advance the down, and calculate new yards to go
                    this.updateDown(parseInt(down) + 1);
                    this.updateYardsToGo(parseInt(yardsToGo) - this.yardsGainedLost)
                }
                
            },
            noGain() {
                // update the workingPlay yardline with the previousPlay yardline and calc the new down
                this.updateYardLine(this.previousPlay.yardLine);
                this.$nextTick(() => {
                    this.calcNewDown();
                });
                

            },
            switchPossession() {
                if (this.homeAbbrev == this.teamPossession.abbrev)
                    this.changePossession(this.visitingAbbrev);
                else
                    this.changePossession(this.homeAbbrev);
            },
            updateDown(down) {
                this.$store.commit(mutationTypes.UPDATE_DOWN + '_WORKINGPLAY', down.toString());
            },
            updateYardsToGo(yardsToGo) {
                this.$store.commit(mutationTypes.UPDATE_YTG + '_WORKINGPLAY', yardsToGo.toString());
            },
            updateYardLine(yardLine) {
                this.$store.commit(mutationTypes.UPDATE_YARDLINE + '_WORKINGPLAY', yardLine);
            }
        }
        
    }
</script>

