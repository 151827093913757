<script>
    import * as mutationTypes from '@/store/mutation_types';
    import { mapState } from 'vuex';
    import FieldPositionUpDown from './FieldPositionUpDown.vue'

    export default {
        name: 'FieldPosition',
        components: {
            FieldPositionUpDown
        },
        props: {
            stateKey: {
                type: String,
                required: true
            },
            isCurrentPlay: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {
                numpad: ''
            }
        },
        created() {

        },
        computed: {
            ...mapState({
                homeAbbrev: state => state.homeTeam.abbrev,
                visitingAbbrev: state => state.visitingTeam.abbrev,
                teamPossession: state => state.teamPossession
            }),
            down: {
                get() {
                    return this.$store.state[this.stateKey].down
                },
                set(value) {
                    this.$store.commit(this.mutationTypeAppend(mutationTypes.UPDATE_DOWN), value)
                }
            },
            yardsToGo: {
                get() {
                    return this.$store.state[this.stateKey].yardsToGo
                },
                set(value) {
                    this.$store.commit(this.mutationTypeAppend(mutationTypes.UPDATE_YTG), value)
                }
            },
            yardLine: {
                get() {
                    return this.$store.state[this.stateKey].yardLine;
                },
                set(value) {
                    this.$store.commit(this.mutationTypeAppend(mutationTypes.UPDATE_YARDLINE), value);
                }

            },
            yardLineSide: {
                get() {
                    return this.$store.state[this.stateKey].yardLineSide;
                },
                set(value) {
                    this.$store.commit(this.mutationTypeAppend(mutationTypes.UPDATE_YARDLINE_SIDE), value)
                }
            },
            ballOnTheirHalf() {
                return this.teamPossession.abbrev == this.yardLineSide
            }
        },
        methods: {
            advanceDownClick() {
                if (this.down == '' || Number.isNaN(this.down) || this.down == '4')
                    this.down = '1';
                else
                    this.down = parseInt(this.down) + 1;

            },
            decreaseDownClick() {
                if (this.down == '' || Number.isNaN(this.down))
                    this.down = '1';
                else if (this.down == '1')
                    this.down = '4';
                else
                    this.down = parseInt(this.down) - 1;

            },
            increaseDecreaseYardLine(value) {
                if (this.yardLine == '' || Number.isNaN(this.yardLine))
                    return;

                let yardLine = parseInt(this.yardLine);

                if (this.teamPossession.abbrev == this.yardLineSide)
                    yardLine = yardLine * -1;

                yardLine = Math.abs(yardLine - value);
                if (yardLine == 51) {
                    this.crossTheFifty();
                    yardLine = 49;
                }
                    
                this.yardLine = yardLine;

            },
            increaseDecreaseDown(value) {
                if (this.down == '' || Number.isNaN(this.down))
                    return;

                let tempValue = parseInt(this.down) + value;
                if (tempValue == 0)
                    tempValue = 4;
                else if (tempValue == 5)
                    tempValue = 1;

                this.down = tempValue.toString();

            },
            increaseDecreaseYardsToGo(value) {
                if (this.yardsToGo == '' || Number.isNaN(this.yardsToGo))
                    return;

                let tempValue = parseInt(this.yardsToGo) + value;
                if (tempValue == 0)
                    tempValue = 1;

                this.yardsToGo = tempValue.toString();
            },
            crossTheFifty() {
                //this.yardLineSide = this.yardLineSide == this.homeAbbrev ? this.homeAbbrev : this.visitingAbbrev;
                this.yardLineSide = this.yardLineSide == this.homeAbbrev ? this.visitingAbbrev : this.homeAbbrev;
            },

            firstDownClick() {
                this.down = "1";
            },
            mutationTypeAppend(mutationType) {
                return mutationType + '_' + this.stateKey.toUpperCase();
            },

        },
        watch: {
            down(newVal) {
                if (newVal == '1')
                    this.yardsToGo = '10';
            }

        }
    }
</script>

<template>
    <div>
        <div class="mb-3 row">
            <div class="col-sm-3">
                <input type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       class="form-control text-center"
                       :id="'yardLine'+stateKey"
                       v-model="yardLine"
                       @blur="$emit('blur-yardline')"
                       @focus="$event.target.select()" />
            </div>
            <div class="col-sm-4">
                <field-position-up-down field="yardLine"
                                        @increase="increaseDecreaseYardLine(1)"
                                        @decrease="increaseDecreaseYardLine(-1)"></field-position-up-down>
            </div>
            <div class="col-sm-2 d-grid gap-2">
                <input type="radio"
                       class="btn-check"
                       :id="'yardsHome'+stateKey"
                       :name="'yardsSide'+stateKey"
                       :value="homeAbbrev"
                       v-model="yardLineSide"
                       tabindex="-1">
                <label class="btn btn-outline-secondary" :for="'yardsHome'+stateKey">{{ homeAbbrev }}</label>
            </div>
            <div class="col-sm-2 d-grid gap-2">
                <input type="radio" class="btn-check" :id="'yardsVisiting'+stateKey" :name="'yardsSide'+stateKey" :value="visitingAbbrev" v-model="yardLineSide" tabindex="-1">
                <label class="btn btn-outline-secondary" :for="'yardsVisiting'+stateKey">{{ visitingAbbrev }}</label>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-sm-3">

                <input type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       class="form-control text-center"
                       id="down"
                       v-model="down"
                       @focus="$event.target.select()" />
            </div>
            <div class="col-sm-4">
                <field-position-up-down field="down"
                                        @increase="increaseDecreaseDown(1)"
                                        @decrease="increaseDecreaseDown(-1)"></field-position-up-down>
            </div>
            <div class="col-sm-5 d-grid gap-2">
                <button class="btn btn-outline-secondary"
                        @click.stop.prevent="firstDownClick"
                        tabindex="-1"
                        v-if="isCurrentPlay">
                    1st
                </button>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-sm-3">
                <input type="text"
                       inputmode="numeric"
                       pattern="[0-9]*"
                       class="form-control text-center"
                       id="yardsToGo"
                       v-model="yardsToGo"
                       @focus="$event.target.select()" />
            </div>
            <div class="col-sm-4">
                <field-position-up-down field="yardsToGo"
                                        @increase="increaseDecreaseYardsToGo(1)"
                                        @decrease="increaseDecreaseYardsToGo(-1)"></field-position-up-down>
            </div>
            <div class="col-sm-2 d-grid gap-2">
                <button class="btn btn-outline-secondary"
                        @click.stop.prevent="$emit('calc-ytg')"
                        tabindex="-1"
                        v-if="isCurrentPlay">
                    <font-awesome-icon :icon="['far', 'calculator']" />
                </button>
            </div>
            <div class="col-sm-2 d-grid gap-2">
                <button class="btn btn-outline-secondary"
                        @click.stop.prevent="$emit('no-gain')"
                        tabindex="-1"
                        v-if="isCurrentPlay">
                    NG
                </button>
            </div>
        </div>

    </div>
</template>