import * as types from './mutation_types';
////export default {
////    [types.MARK_AS_CLOSED](state, itemId) {
////        const item = state.items.find(x => x.id === itemId);

////        if (!item) {
////            return;
////        }

////        Vue.set(item, 'closed', true);
////    },
////}

export default {
    [types.RECEIVE_HOME_PLAYERS](state, players) {
        state.homePlayers = players;
    },
    [types.RECEIVE_VISITING_PLAYERS](state, players) {
        state.visitingPlayers = players;
    },
    [types.CHANGE_POSSESSION](state, possession) {
        state.teamPossession = possession;
    },
    [types.UPDATE_DOWN + '_WORKINGPLAY'](state, down) {
        state.workingPlay.down = down;
    },
    [types.UPDATE_DOWN + '_PREVIOUSPLAY'](state, down) {
        state.previousPlay.down = down;
    },
    [types.UPDATE_YTG + '_WORKINGPLAY'](state, ytg) {
        state.workingPlay.yardsToGo = ytg
    },
    [types.UPDATE_YTG + '_PREVIOUSPLAY'](state, ytg) {
        state.previousPlay.yardsToGo = ytg
    },
    [types.UPDATE_YARDLINE + '_WORKINGPLAY'](state, yardLine) {
        state.workingPlay.yardLine = yardLine;
    },
    [types.UPDATE_YARDLINE + '_PREVIOUSPLAY'](state, yardLine) {
        state.previousPlay.yardLine = yardLine;
    },
    [types.UPDATE_YARDLINE_SIDE + '_WORKINGPLAY'](state, yardLineSide) {
        state.workingPlay.yardLineSide = yardLineSide;
    },
    [types.UPDATE_YARDLINE_SIDE + '_PREVIOUSPLAY'](state, yardLineSide) {
        state.previousPlay.yardLineSide = yardLineSide;
    },
    [types.UPDATE_POSSESSION_WORKING_PLAY](state, possession) {
        state.workingPlay.possession = possession
    },
    [types.UPDATE_OFFENSE_PLAYER_1](state, player) {
        state.workingPlay.offensePlayer1 = player
    },
    [types.UPDATE_OFFENSE_PLAYER_2](state, player) {
        state.workingPlay.offensePlayer2 = player
    },
    [types.UPDATE_TACKLERS](state, players) {
        state.workingPlay.tacklers = players
    }
}