<template>
    <div id="app" class="container-fluid">

        <game-play v-model="currentPlay"
                   v-show="gamePlayShown"></game-play>
        <possession></possession>
        <div class="row mb-3">
            <div class="col-1 d-grid gap-2 mx-auto">
                <button class="btn btn-danger"
                        v-show="clearPlayerListShown"
                        @click.stop.prevent="clearRostersClick">X</button>
            </div>
            <div class="col-5">
                <player-list :players="selectedHomePlayers"></player-list>
            </div>
            <div class="col">
                <player-list :players="selectedVisitingPlayers"></player-list>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <player-selector :players="homePlayers"
                                 @select="onHomePlayerSelect"
                                 :show-full-roster="rostersShown"></player-selector>
            </div>
            <div class="col">
                <player-selector :players="visitingPlayers"
                                 @select="onVisitingPlayerSelect"
                                 :show-full-roster="rostersShown"></player-selector>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-5 d-grid gap-2 mx-auto">
                <button class="btn btn-outline-secondary"
                        v-show="!gamePlayShown"
                        @click.stop.prevent="toGamePlayClick">To Game Play</button>
                <button class="btn btn-outline-secondary"
                        v-show="!rostersShown"
                        @click.stop.prevent="toRostersClick">To Rosters</button>
            </div>
            
        </div>

    </div>
</template>

<script>

    import PlayerSelector from './components/PlayerSelector.vue';
    import PlayerList from './components/PlayerList.vue';
    import GamePlay from './components/GamePlay.vue'
    import Possession from './components/Possession.vue';

    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'App',
        components: {
            Possession,
            PlayerSelector,
            PlayerList,
            GamePlay
        }, 
        created() {
            this.getHomePlayers();
            this.getVisitingPlayers();
        },
        data() {
            return {
                homeTeam: { 'name': 'River Valley', 'abbrev': 'RV' },
                visitingTeam: { 'name': 'Edgewood', 'abbrev': 'EW' },
                teamPossession: '',
                currentPlay: '',
                selectedHomePlayerIds: [],
                selectedVisitingPlayerIds: [],
                gamePlayShown: true,
                rostersShown: false,
            }
        },
        computed: {
            ...mapState({
                homePlayers: state => state.homePlayers,
                visitingPlayers: state => state.visitingPlayers
            }),
            selectedHomePlayers() {
                const vm = this;
                return vm.homePlayers.filter(p => vm.selectedHomePlayerIds.includes(p.id));
            },
            selectedVisitingPlayers() {
                const vm = this;
                return vm.visitingPlayers.filter(p => vm.selectedVisitingPlayerIds.includes(p.id));
            },
            clearPlayerListShown() {
                return this.selectedHomePlayerIds.length > 0 || this.selectedVisitingPlayerIds.length > 0;
            }
        },
        mounted() {
            if (this.$storage.has('currentPlay'))
                this.currentPlay = this.$storage.get('currentPlay');
        },
        methods: {
            ...mapActions([
                'getHomePlayers',
                'getVisitingPlayers'
            ]),
            saveData() {
                this.$storage.set('currentPlay', this.currentPlay);
            },
            onHomePlayerSelect(id) {
                this.selectedHomePlayerIds.push(id);
            },
            onVisitingPlayerSelect(id) {
                this.selectedVisitingPlayerIds.push(id);
            },
            toRostersClick() {
                this.rostersShown = true;
                this.gamePlayShown = false;
            },
            toGamePlayClick() {
                this.rostersShown = false;
                this.gamePlayShown = true;
            },
            clearRostersClick() {
                this.selectedVisitingPlayerIds = [];
                this.selectedHomePlayerIds = [];
            }
        },
        watch: {
            currentPlay() {
                this.saveData();
            }
        }
    
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top:1rem;
    }
</style>
