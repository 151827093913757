////// get all the users with pets
////export const getUsersWithPets = (state, getters) => {
////    return state.users.filter(user => user.pet !== undefined);
////};

export const homeTeamHasPossession = (state) => {
    return state.teamPossession.name == state.homeTeam.name
}

export const visitingTeamHasPossession = (state) => {
    return state.teamPossession.name == state.visitingTeam.name
}

export const readyForYardCalculations = (state) => {
    return !(state.teamPossession.abbrev == '' ||
        state.workingPlay.yardLineSide == '' ||
        state.previousPlay.yardLineSide == '' ||
        state.previousPlay.yardLine == '' ||
        state.workingPlay.yardLine == '')
}
